import { Bugsnag } from '@utils/Bugsnag';
import { RequestError } from '@utils/api/RequestError';
import { InstallStatusEnum } from '@utils/api/install/constants';
import type { AuthDetails } from '../shopifyAdminRequest';
import { shopifyAdminRequest } from '../shopifyAdminRequest';
import type { ScriptTagsInstallStatus, ScriptTag, ShopifyScriptTag } from './scriptTagsInstallStatus';
import { scriptTagsInstallStatus } from './scriptTagsInstallStatus';

// == Types ================================================================

// == Constants ============================================================

// == Functions ============================================================

const createScriptTag = async (authDetails: AuthDetails, scriptTag: ScriptTag): Promise<ScriptTag> => {
  try {
    const { PendingInstall, Installed } = InstallStatusEnum;
    const { status, src, event, display_scope: displayScope } = scriptTag;
    if (status !== PendingInstall) return scriptTag;

    const { data } = await shopifyAdminRequest<Record<'script_tag', ShopifyScriptTag>>(authDetails, '/script_tags', {
      method: 'POST',
      body: JSON.stringify({
        script_tag: {
          src,
          event,
          display_scope: displayScope,
        },
      }),
    });
    if (!data?.script_tag) throw new RequestError();

    return scriptTagWithStatus({ ...scriptTag, ...data.script_tag }, Installed, true);
  } catch (error) {
    if (error instanceof Error) Bugsnag.notify(error);
    return scriptTagWithErrorStatus(scriptTag);
  }
};

const updateScriptTag = async (authDetails: AuthDetails, scriptTag: ScriptTag): Promise<ScriptTag> => {
  try {
    const { PendingUpdate, Updated } = InstallStatusEnum;
    const { id, status, src, event, display_scope: displayScope } = scriptTag;
    if (status !== PendingUpdate) return scriptTag;
    if (!id) throw new RequestError();

    const { data } = await shopifyAdminRequest<Record<'script_tag', ShopifyScriptTag>>(
      authDetails,
      `/script_tags/${id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          script_tag: {
            src,
            event,
            display_scope: displayScope,
          },
        }),
      },
    );
    if (!data || !data.script_tag) throw new RequestError();

    return scriptTagWithStatus({ ...scriptTag, ...data.script_tag }, Updated, true);
  } catch (error) {
    if (error instanceof Error) Bugsnag.notify(error);
    return scriptTagWithErrorStatus(scriptTag);
  }
};

const deleteScriptTag = async (authDetails: AuthDetails, scriptTag: ScriptTag): Promise<ScriptTag> => {
  try {
    const { PendingDelete, Deleted } = InstallStatusEnum;
    const { id, status } = scriptTag;
    if (status !== PendingDelete) return scriptTag;
    if (!id) throw new RequestError();

    await shopifyAdminRequest(authDetails, `/script_tags/${id}`, {
      method: 'DELETE',
    });
    return scriptTagWithStatus(scriptTag, Deleted, true);
  } catch (error) {
    if (error instanceof Error) Bugsnag.notify(error);
    return scriptTagWithErrorStatus(scriptTag);
  }
};

const scriptTagWithErrorStatus = (
  scriptTag: ScriptTag,
): {
  status: InstallStatusEnum;
  success: boolean;
  name: string;
  displayName?: string | undefined;
  id?: number | undefined;
  src?: string | undefined;
  event?: string | undefined;
  INSTALLED_at?: Date | undefined;
  updated_at?: Date | undefined;
  display_scope?: string | undefined;
} => scriptTagWithStatus(scriptTag, InstallStatusEnum.Error, false);

const scriptTagWithStatus = (
  scriptTag: ScriptTag,
  status: InstallStatusEnum,
  success: boolean,
): {
  status: InstallStatusEnum;
  success: boolean;
  name: string;
  displayName?: string | undefined;
  id?: number | undefined;
  src?: string | undefined;
  event?: string | undefined;
  INSTALLED_at?: Date | undefined;
  updated_at?: Date | undefined;
  display_scope?: string | undefined;
} => ({
  ...scriptTag,
  status,
  success,
});

// == Exports ==============================================================

export const scriptTagsInstall = async (authDetails: AuthDetails): Promise<ScriptTagsInstallStatus> => {
  const installStatus = await scriptTagsInstallStatus(authDetails);
  const { installSuccess, scriptTags } = installStatus;
  if (installSuccess) return installStatus;

  const scriptTagPromises = scriptTags.map(async (scriptTag) => {
    const { PendingInstall, PendingDelete, PendingUpdate } = InstallStatusEnum;
    const { status } = scriptTag;
    switch (status) {
      case PendingInstall:
        return createScriptTag(authDetails, scriptTag);
      case PendingUpdate:
        return updateScriptTag(authDetails, scriptTag);
      case PendingDelete:
        return deleteScriptTag(authDetails, scriptTag);
      default:
        return scriptTag;
    }
  });

  const updatedScriptTags = await Promise.all(scriptTagPromises);
  const newInstallSuccess = updatedScriptTags.every(({ success }) => success);
  return { installSuccess: newInstallSuccess, scriptTags: updatedScriptTags };
};
