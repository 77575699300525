import React from 'react';
import type { SpinnerProps } from '@shopify/polaris';
import { Spinner } from '@shopify/polaris';

// == Types ================================================================

interface SpinnerMessageProps extends SpinnerProps {
  message: string;
}

// == Constants ============================================================

SpinnerMessage.defaultProps = {
  message: '',
  spinnerColor: 'teal',
};

// == Component ============================================================

export function SpinnerMessage({ message, ...spinnerProps }: SpinnerMessageProps): JSX.Element {
  return (
    <div className="spinner-message">
      <p>
        <Spinner {...spinnerProps} size="small" />
        <span>{message}</span>
      </p>
    </div>
  );
}

// == Styles ===============================================================
