import React from 'react';
import { EmptyState } from '@shopify/polaris';

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

export function EmptyErrorState(): JSX.Element {
  return (
    <EmptyState
      action={{
        content: 'Contact FlipGive Support',
        url: 'https://www.flipgive.com/pages/contact',
        external: true,
      }}
      heading="There was an error loading the app."
      image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
    >
      Please contact FlipGive support and we will look into the issue.
    </EmptyState>
  );
}

// == Styles ===============================================================
