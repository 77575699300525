import { useAppBridge } from '@shopify/app-bridge-react';
import { useMemo } from 'react';
import { useParamValue } from './useParamValue';

// == Types ================================================================

// == Constants ============================================================

// == Exports ==============================================================

export function useShopOrigin(): string | null {
  const app = useAppBridge();
  const shopParam = useParamValue('shop');

  return useMemo((): string | null => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!app) return shopParam;
    return app.hostOrigin.replace('https://', '');
  }, [app, shopParam]);
}
