import { TickSmallMinor, AlertMinor } from '@shopify/polaris-icons';
import { authenticatedFetch } from '@shopify/app-bridge-utils';
import { useAppBridge } from '@shopify/app-bridge-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { SpinnerMessage } from '@components/SpinnerMessage';
import { IconMessage } from '@components/IconMessage';
import { InstallStatus } from '@components/status/InstallStatus';
import type { WebhooksInstallStatus } from '@utils/api/install';
import { WEBHOOKS_INSTALL_PATH, WEBHOOKS_STATUS_PATH } from '@utils';
import { useShopOrigin } from '@hooks';
import { ErrorBanner } from '@components/ErrorBanner';

// == Types ================================================================

interface WebhooksStatusProps {
  hasValidToken: boolean;
}

// == Constants ============================================================

// == Component ============================================================

export function WebhooksStatus({ hasValidToken }: WebhooksStatusProps): JSX.Element {
  const app = useAppBridge();
  const shopOrigin = useShopOrigin();

  const { data, isSuccess, isLoading, isError } = useQuery({
    queryKey: [WEBHOOKS_STATUS_PATH],
    queryFn: async ({ signal }) => {
      if (!shopOrigin) throw new Error('Shop origin is null');

      const response = await authenticatedFetch(app)(`${WEBHOOKS_STATUS_PATH}?shop=${shopOrigin}`, {
        method: 'GET',
        signal,
      });
      if (!response.ok) throw new Error('Failed to fetch script tags status');
      return response.json() as Promise<WebhooksInstallStatus>;
    },
  });

  const {
    mutate,
    isLoading: isInstalling,
    isError: hasInstallError,
  } = useMutation({
    mutationKey: [WEBHOOKS_INSTALL_PATH],
    mutationFn: async () => {
      if (!shopOrigin) throw new Error('Shop origin is null');
      const response = await authenticatedFetch(app)(`${WEBHOOKS_INSTALL_PATH}?shop=${shopOrigin}`, {
        method: 'GET',
      });
      if (!response.ok) throw new Error('Failed to fetch script tags status');
      const json = (await response.json()) as WebhooksInstallStatus;
      if (!json.installSuccess) throw new Error('Failed to install webhooks');
      return json.installSuccess;
    },
  });

  // if (isLoading && fetchStatus === 'idle') return <SpinnerMessage message="Waiting for Shopify token result" />;
  if (isLoading || isInstalling) {
    const message = isInstalling ? 'Please wait while we update the webhooks' : 'Verifying webhooks';
    return <SpinnerMessage message={message} />;
  }

  const message = (): string => {
    if (!hasValidToken) return 'Please re-install the app';
    if (isSuccess) return 'All webhooks are installed.';
    return 'One or more webhooks need to be installed or updated.';
  };
  return (
    <>
      <IconMessage
        actionText="Install/update webhooks"
        actionTooltip={!hasValidToken ? 'Please re-install the app first' : ''}
        details={data?.webhooks.map((webhook) => {
          return (
            <InstallStatus key={webhook.topic} name={webhook.topic} status={webhook.status} textStyleVariation="code" />
          );
        })}
        disableAction={!hasValidToken}
        displayDetails={!isSuccess}
        iconColor={isSuccess && hasValidToken ? 'success' : 'critical'}
        iconSource={isSuccess && hasValidToken ? TickSmallMinor : AlertMinor}
        message={message()}
        onClickAction={mutate}
      />
      {isError ? <ErrorBanner message="There was a problem checking the webhooks installation." /> : null}
      {hasInstallError ? <ErrorBanner message="There was a problem installing the webhooks." /> : null}
    </>
  );
}

// == Styles ===============================================================
