import React from 'react';
import type { BadgeProps, TextStyleProps } from '@shopify/polaris';
import { Badge, TextStyle } from '@shopify/polaris';
import { InstallStatusEnum } from '@utils/api/install';

// == Types ================================================================

interface InstallStatusProps {
  name?: string;
  status: InstallStatusEnum;
  textStyleVariation: TextStyleProps['variation'];
}

type BadgeStatusMap = {
  [TKey in InstallStatusEnum]: { badgeName: string; badgeStatus: BadgeProps['status'] };
};

// == Constants ============================================================

const BADGE_STATUS_MAP: BadgeStatusMap = {
  [InstallStatusEnum.Installed]: { badgeName: 'Installed', badgeStatus: 'success' },
  [InstallStatusEnum.PendingInstall]: { badgeName: 'Pending Install', badgeStatus: 'attention' },
  [InstallStatusEnum.Updated]: { badgeName: 'Updated', badgeStatus: 'success' },
  [InstallStatusEnum.PendingUpdate]: { badgeName: 'Pending Update', badgeStatus: 'attention' },
  [InstallStatusEnum.Deleted]: { badgeName: 'Deleted', badgeStatus: 'warning' },
  [InstallStatusEnum.PendingDelete]: { badgeName: 'Pending Delete', badgeStatus: 'warning' },
  [InstallStatusEnum.Error]: { badgeName: 'Error', badgeStatus: 'warning' },
};

InstallStatus.defaultProps = {
  textStyleVariation: 'default',
};

// == Component ============================================================

export function InstallStatus({ name, status, textStyleVariation }: InstallStatusProps): JSX.Element | null {
  if (!name) return null;

  const { badgeName, badgeStatus } = BADGE_STATUS_MAP[status];
  return (
    <div className={`install-status-${status.toLowerCase()}`}>
      <span>
        <TextStyle variation={textStyleVariation}>{name}</TextStyle>
      </span>
      <Badge size="small" status={badgeStatus}>
        {badgeName}
      </Badge>
    </div>
  );
}

// == Styles ===============================================================
