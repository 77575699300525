// From https://github.com/maticzav/nookies
import type { NextPageContext } from 'next';
import type { CookieParseOptions } from 'cookie';
import { parse } from 'cookie';
import { IS_CLIENT } from '@utils/constants';

// == Types ================================================================

// == Constants ============================================================

// == Functions ============================================================

// == Exports ==============================================================

export function cookies(
  ctx?: NextPageContext | null,
  options?: CookieParseOptions,
): Record<string, string | undefined> {
  const cookieHeader = ctx?.req?.headers.cookie;

  if (cookieHeader) return parse(cookieHeader, options);
  if (IS_CLIENT) return parse(document.cookie, options);

  return {};
}
