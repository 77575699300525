import { Bugsnag } from '@utils/Bugsnag';
import { RequestError } from '@utils/api/RequestError';
import { URLS } from '@utils/constants';
import { InstallStatusEnum } from '@utils/api/install/constants';
import type { AuthDetails } from '../shopifyAdminRequest';
import { shopifyAdminRequest } from '../shopifyAdminRequest';
import type { WebhooksInstallStatus, Webhook, ShopifyWebhook } from './webhooksInstallStatus';
import { webhooksInstallStatus, WEBHOOK_DATA_FORMAT } from './webhooksInstallStatus';

// == Types ================================================================

// == Constants ============================================================

// == Functions ============================================================

const createWebhook = async (
  authDetails: AuthDetails,
  webhook: Webhook,
): Promise<{
  status: InstallStatusEnum;
  success: boolean;
  id?: number | undefined;
  topic?: string | undefined;
  created_at?: Date | undefined;
  updated_at?: Date | undefined;
  format?: string | undefined;
  fields?: string[] | undefined;
  metafield_namespaces?: string[] | undefined;
  api_version?: string | undefined;
}> => {
  try {
    const { PendingInstall, Installed } = InstallStatusEnum;
    const { status, topic } = webhook;
    if (status !== PendingInstall) return webhook;

    const { FLIPGIVE_WEBHOOKS_URL } = URLS(authDetails.shopOrigin);
    const body = JSON.stringify({
      webhook: {
        topic,
        address: FLIPGIVE_WEBHOOKS_URL,
        format: WEBHOOK_DATA_FORMAT,
      },
    });
    const { data } = await shopifyAdminRequest<Record<'webhook', ShopifyWebhook>>(authDetails, '/webhooks', {
      method: 'POST',
      body,
    });
    if (!data || !data.webhook) throw new RequestError();

    const { address: _, ...webhookProperties } = webhook;
    return webhookWithStatus({ ...webhook, ...webhookProperties }, Installed, true);
  } catch (error) {
    if (error instanceof Error) Bugsnag.notify(error);
    return webhookWithErrorStatus(webhook);
  }
};

const updateWebhook = async (
  authDetails: AuthDetails,
  webhook: Webhook,
): Promise<{
  status: InstallStatusEnum;
  success: boolean;
  id?: number | undefined;
  topic?: string | undefined;
  created_at?: Date | undefined;
  updated_at?: Date | undefined;
  format?: string | undefined;
  fields?: string[] | undefined;
  metafield_namespaces?: string[] | undefined;
  api_version?: string | undefined;
}> => {
  try {
    const { PendingUpdate, Updated } = InstallStatusEnum;
    const { id, status, topic } = webhook;
    if (status !== PendingUpdate) return webhook;

    const { FLIPGIVE_WEBHOOKS_URL } = URLS(authDetails.shopOrigin);
    const body = JSON.stringify({
      webhook: {
        topic,
        address: FLIPGIVE_WEBHOOKS_URL,
        format: WEBHOOK_DATA_FORMAT,
      },
    });
    if (!id) throw new RequestError();
    const { data } = await shopifyAdminRequest<Record<'webhook', ShopifyWebhook>>(authDetails, `/webhooks/${id}`, {
      method: 'PUT',
      body,
    });
    if (!data || !data.webhook) throw new RequestError();
    const { address: _, ...webhookProperties } = webhook;
    return webhookWithStatus({ ...webhook, ...webhookProperties }, Updated, true);
  } catch (error) {
    if (error instanceof Error) Bugsnag.notify(error);
    return webhookWithErrorStatus(webhook);
  }
};

const deleteWebhook = async (
  authDetails: AuthDetails,
  webhook: Webhook,
): Promise<{
  status: InstallStatusEnum;
  success: boolean;
  id?: number | undefined;
  topic?: string | undefined;
  created_at?: Date | undefined;
  updated_at?: Date | undefined;
  format?: string | undefined;
  fields?: string[] | undefined;
  metafield_namespaces?: string[] | undefined;
  api_version?: string | undefined;
}> => {
  try {
    const { PendingDelete, Deleted } = InstallStatusEnum;
    const { id, status } = webhook;
    if (status !== PendingDelete) return webhook;
    if (!id) throw new RequestError();

    await shopifyAdminRequest(authDetails, `/webhooks/${id}`, {
      method: 'DELETE',
    });
    return webhookWithStatus(webhook, Deleted, true);
  } catch (error) {
    if (error instanceof Error) Bugsnag.notify(error);
    return webhookWithErrorStatus(webhook);
  }
};

const webhookWithErrorStatus = (
  webhook: Webhook,
): {
  status: InstallStatusEnum;
  success: boolean;
  id?: number | undefined;
  topic?: string | undefined;
  created_at?: Date | undefined;
  updated_at?: Date | undefined;
  format?: string | undefined;
  fields?: string[] | undefined;
  metafield_namespaces?: string[] | undefined;
  api_version?: string | undefined;
} => webhookWithStatus(webhook, InstallStatusEnum.Error, false);

const webhookWithStatus = (
  { address: _, ...webhook }: Webhook,
  status: InstallStatusEnum,
  success: boolean,
): {
  status: InstallStatusEnum;
  success: boolean;
  id?: number | undefined;
  topic?: string | undefined;
  created_at?: Date | undefined;
  updated_at?: Date | undefined;
  format?: string | undefined;
  fields?: string[] | undefined;
  metafield_namespaces?: string[] | undefined;
  api_version?: string | undefined;
} => ({
  ...webhook,
  status,
  success,
});

// == Exports ==============================================================

export const webhooksInstall = async (authDetails: AuthDetails): Promise<WebhooksInstallStatus> => {
  const installStatus = await webhooksInstallStatus(authDetails);
  const { installSuccess, webhooks } = installStatus;
  if (installSuccess) return installStatus;

  const webhookPromises = webhooks.map(async (webhook) => {
    const { PendingInstall, PendingDelete, PendingUpdate } = InstallStatusEnum;

    const { status } = webhook;
    switch (status) {
      case PendingInstall:
        return createWebhook(authDetails, webhook);
      case PendingUpdate:
        return updateWebhook(authDetails, webhook);
      case PendingDelete:
        return deleteWebhook(authDetails, webhook);
      default:
        return webhook;
    }
  });

  const updatedWebhooks = await Promise.all(webhookPromises);
  return {
    installSuccess: updatedWebhooks.every(({ success }) => success),
    webhooks: updatedWebhooks,
  };
};
