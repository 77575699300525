/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { IS_DEVELOPMENT } from '@utils/constants';

// == Types ================================================================

export interface JsonResponse<TResponseType> {
  data?: TResponseType;
  ok: boolean;
  status: number;
  statusText: string;
}

// == Constants ============================================================

const DISABLE_DEVELOPMENT_LOGGING = true;

// == Functions ============================================================

/* eslint-disable no-console */
const logRequestForDebugging = (endpoint: string, options: RequestInit): void => {
  if (!IS_DEVELOPMENT || DISABLE_DEVELOPMENT_LOGGING) return;

  console.log(`===========${String(options.method)} ${endpoint} REQUEST ==================\n`);
  if (typeof options.body === 'string') {
    console.log(`Body: ${options.body.substr(0, 500)}\n`);
    console.log('==================================================================\n\n');
  }
};

const logResponseForDebugging = (
  endpoint: string,
  options: RequestInit,
  response: Response,
  responseBody: unknown = {},
  error: unknown = null,
): void => {
  if (!IS_DEVELOPMENT || DISABLE_DEVELOPMENT_LOGGING) return;

  console.log(`===========${String(options.method)} ${endpoint} RESPONSE ================\n`);
  console.log(`
    Status ${response.status}: ${response.statusText}\n
    Headers: ${JSON.stringify(options.headers)}\n
    Body: ${JSON.stringify(responseBody)}\n
    Error: ${JSON.stringify(error)}\n
  `);
  console.log('==================================================================\n\n');
};
/* eslint-enable no-console */

// == Exports ==============================================================

export async function fetchRequest<ResponseType>(
  endpoint: string,
  options: RequestInit = {},
): Promise<JsonResponse<ResponseType>> {
  if (options.body && typeof options.body !== 'string') {
    options.body = JSON.stringify(options.body);
  }
  options.headers = {
    Accept: 'application/json',
    ...options.headers,
  };
  if (options.headers && !options.headers?.['Content-Type']) {
    options.headers['Content-Type'] = 'application/json';
  }
  logRequestForDebugging(endpoint, options);
  const response = await fetch(endpoint, options);
  let data: ResponseType | undefined;
  let error: unknown;
  try {
    data = (await response.json()) as ResponseType;
  } catch (parseError) {
    error = parseError;
  }
  logResponseForDebugging(endpoint, options, response, data, error);
  return {
    data,
    ok: response.ok,
    status: response.status,
    statusText: response.statusText,
  };
}
