import { useMemo } from 'react';
import { IS_CLIENT } from '@utils';

// == Types ================================================================

// == Constants ============================================================

// == Exports ==============================================================

export function useParamValue(name: string): string | null {
  return useMemo(() => {
    if (!IS_CLIENT) return null;

    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name) ?? null;
  }, [name]);
}
