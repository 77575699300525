import React, { Fragment } from 'react';
import BugsnagClient from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { IS_API, publicConstants } from '@utils/constants';

export const Bugsnag = BugsnagClient.start({
  apiKey: publicConstants.NEXT_PUBLIC_BUGSNAG_API_KEY,
  appType: IS_API ? 'API' : 'CLIENT',
  releaseStage: publicConstants.NEXT_PUBLIC_VERCEL_ENV,
  plugins: [new BugsnagPluginReact()],
});

const plugin = Bugsnag.getPlugin('react');
export const ErrorBoundary = plugin ? plugin.createErrorBoundary(React) : Fragment;
