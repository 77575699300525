import crypto from 'crypto';

// == Types ================================================================

// == Constants ============================================================

// == Exports ==============================================================

export function generateNonce(): string {
  return crypto.randomBytes(16).toString('hex');
}
