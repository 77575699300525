import React from 'react';
import { TickSmallMinor, AlertMinor } from '@shopify/polaris-icons';
import { authenticatedFetch } from '@shopify/app-bridge-utils';
import { useAppBridge } from '@shopify/app-bridge-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { SpinnerMessage } from '@components/SpinnerMessage';
import { IconMessage } from '@components/IconMessage';
import { InstallStatus } from '@components/status/InstallStatus';
import type { ScriptTagsInstallStatus } from '@utils/api/install';
import { SCRIPT_TAGS_INSTALL_PATH, SCRIPT_TAGS_STATUS_PATH } from '@utils';
import { useShopOrigin } from '@hooks';
import { ErrorBanner } from '@components/ErrorBanner';

// == Types ================================================================

interface ScriptTagsStatusProps {
  hasValidToken: boolean;
}

// == Constants ============================================================

// == Component ============================================================

export function ScriptTagsStatus({ hasValidToken }: ScriptTagsStatusProps): JSX.Element {
  const shopOrigin = useShopOrigin();
  const app = useAppBridge();

  const { data, isSuccess, isLoading, isError } = useQuery<ScriptTagsInstallStatus>({
    queryKey: [SCRIPT_TAGS_STATUS_PATH],
    queryFn: async ({ signal }) => {
      if (!shopOrigin) throw new Error('Shop origin is null');

      const response = await authenticatedFetch(app)(`${SCRIPT_TAGS_STATUS_PATH}?shop=${shopOrigin}`, {
        method: 'GET',
        signal,
      });
      if (!response.ok) throw new Error('Failed to fetch script tags status');
      return response.json();
    },
    enabled: hasValidToken,
  });

  const {
    mutate,
    isLoading: isInstalling,
    isError: hasInstallError,
  } = useMutation({
    mutationKey: [SCRIPT_TAGS_INSTALL_PATH],
    mutationFn: async () => {
      if (!shopOrigin) throw new Error('Shop origin is null');
      const response = await authenticatedFetch(app)(`${SCRIPT_TAGS_INSTALL_PATH}?shop=${shopOrigin}`, {
        method: 'GET',
      });
      if (!response.ok) throw new Error('Failed to fetch script tags status');
      const json = (await response.json()) as ScriptTagsInstallStatus;
      if (!json.installSuccess) throw new Error('Failed to install script tags');
      return json.installSuccess;
    },
  });
  //
  //   if (isLoading && fetchStatus === 'idle') {
  //     return <SpinnerMessage message="Waiting for Shopify token result" />;
  //   }
  if (isLoading || isInstalling) {
    return <SpinnerMessage message={isInstalling ? 'Updating script tags' : 'Verifying script tags'} />;
  }
  const message = (): string => {
    if (!hasValidToken) return 'Please re-install the app';
    if (isSuccess) return 'All script tags are installed.';
    return 'One or more script tags need to be installed or updated.';
  };

  return (
    <>
      <IconMessage
        actionText="Install/update script tags"
        actionTooltip={!hasValidToken ? 'Please re-install the app first' : ''}
        details={data?.scriptTags.map((scriptTag) => {
          return <InstallStatus key={scriptTag.id} name={scriptTag.displayName} status={scriptTag.status} />;
        })}
        disableAction={!hasValidToken}
        displayDetails={!isSuccess}
        iconColor={isSuccess ? 'success' : 'critical'}
        iconSource={isSuccess ? TickSmallMinor : AlertMinor}
        message={message()}
        onClickAction={mutate}
      />
      {isError ? <ErrorBanner message="There was a problem checking the script tags installation." /> : null}
      {hasInstallError ? <ErrorBanner message="There was a problem installing the script tags." /> : null}
    </>
  );
}

// == Styles ===============================================================
