import { RequestError } from '@utils/api/RequestError';
import { InstallStatusEnum } from '@utils/api/install/constants';
import { APP_HOST_URL } from '@utils';
import type { AuthDetails } from '../shopifyAdminRequest';
import { shopifyAdminRequest } from '../shopifyAdminRequest';

// == Types ================================================================

export interface ShopifyScriptTag {
  id: number;
  src: string;
  event: string;
  INSTALLED_at: Date;
  updated_at: Date;
  display_scope: string;
}

export interface ScriptTag extends Partial<ShopifyScriptTag> {
  name: string;
  displayName?: string;
  status: InstallStatusEnum;
  success: boolean;
}

export interface ScriptTagsInstallStatus {
  installSuccess: boolean;
  scriptTags: ScriptTag[];
}

// == Constants ============================================================

const SCRIPT_TAGS = [
  {
    name: 'REFERRAL_CHECK',
    displayName: 'Referral Check Script',
    src: `${APP_HOST_URL}/script_tag_assets/flipgive_referral_check.min.js`,
    event: 'onload',
    display_scope: 'online_store',
  },
];

// == Functions ============================================================

const getScriptTagsWithStatuses = (
  installedScriptTags: ShopifyScriptTag[] | undefined,
): (
  | {
      id: undefined;
      status: InstallStatusEnum;
      success: boolean;
      name: string;
      displayName: string;
      src: string;
      event: string;
      display_scope: string;
    }
  | {
      status: InstallStatusEnum;
      success: boolean;
      id: number;
      src: string;
      event: string;
      INSTALLED_at: Date;
      updated_at: Date;
      display_scope: string;
      name: string;
      displayName: string;
    }
)[] => {
  const { Installed, PendingInstall, PendingUpdate, PendingDelete } = InstallStatusEnum;
  const scriptTags = SCRIPT_TAGS.map((scriptTag) => {
    const { src, display_scope: displayScope } = scriptTag;
    const matchingScriptTag = installedScriptTags?.find((script) => script.src === src);

    if (matchingScriptTag === undefined) {
      return {
        ...scriptTag,
        id: undefined,
        status: PendingInstall,
        success: false,
      };
    }
    // If the display_scope is not matching then we need to update it.
    const correctDisplayScope = matchingScriptTag.display_scope === displayScope;
    return {
      ...scriptTag,
      ...matchingScriptTag,
      status: correctDisplayScope ? Installed : PendingUpdate,
      success: Boolean(correctDisplayScope),
    };
  });

  const goodScriptTagIds = scriptTags.map((goodScriptTag) => goodScriptTag.id);
  installedScriptTags?.forEach((scriptTag) => {
    const { id } = scriptTag;
    if (goodScriptTagIds.includes(id)) return;

    scriptTags.push({
      ...scriptTag,
      displayName: `ScriptTag with ID ${id}`,
      name: String(id),
      status: PendingDelete,
      success: false,
    });
  });
  return scriptTags;
};

const getInstalledScriptTags = async (authDetails: AuthDetails): Promise<ShopifyScriptTag[] | undefined> => {
  const { ok, data } = await shopifyAdminRequest<Record<'script_tags', ShopifyScriptTag[]>>(
    authDetails,
    '/script_tags',
    {
      method: 'GET',
    },
  );
  if (!ok || !data) {
    throw new RequestError({
      message: 'Bad response fetching script tags',
    });
  }
  return data.script_tags;
};

// == Exports ==============================================================

export const scriptTagsInstallStatus = async (
  authDetails: AuthDetails,
): Promise<{
  scriptTags: (
    | {
        id: undefined;
        name: string;
        status: InstallStatusEnum;
        success: boolean;
        src: string;
        event: string;
        displayName: string;
        display_scope: string;
      }
    | {
        id: number;
        name: string;
        status: InstallStatusEnum;
        success: boolean;
        src: string;
        event: string;
        INSTALLED_at: Date;
        updated_at: Date;
        display_scope: string;
        displayName: string;
      }
  )[];
  installSuccess: boolean;
}> => {
  const installedScriptTags = await getInstalledScriptTags(authDetails);
  const scriptTags = getScriptTagsWithStatuses(installedScriptTags);
  const installSuccess = Object.values(scriptTags).every((scriptTag) => scriptTag.success);

  return { scriptTags, installSuccess };
};
