import Cookies from 'js-cookie';
import { REQUIRED_ACCESS_SCOPES, OAUTH_CALLBACK_PATH } from '@utils/api/auth/constants';
import { APP_HOST_URL, publicConstants } from '@utils/constants';
import { generateNonce } from './api/auth/generateNonce';

// == Types ================================================================

// == Constants ============================================================

const REDIRECT_URL = `${APP_HOST_URL}${OAUTH_CALLBACK_PATH}`;

// == Functions ============================================================

// == Exports ==============================================================

export function oauthRedirectParams(): string {
  const requestNonce = generateNonce();
  Cookies.set('shopifyNonce', requestNonce, { path: '/' });

  const params = new URLSearchParams({
    state: requestNonce,
    scope: REQUIRED_ACCESS_SCOPES.join(', '),
    client_id: publicConstants.NEXT_PUBLIC_SHOPIFY_API_KEY,
    redirect_uri: REDIRECT_URL,
  }).toString();

  return params;
}
