import { TickSmallMinor, AlertMinor } from '@shopify/polaris-icons';
import { SkeletonBodyText } from '@shopify/polaris';
import { useAppBridge } from '@shopify/app-bridge-react';
import { useQuery } from '@tanstack/react-query';
import { authenticatedFetch } from '@shopify/app-bridge-utils';
import { SpinnerMessage } from '@components/SpinnerMessage';
import { IconMessage } from '@components/IconMessage';
import { FLIPGIVE_SETUP_STATUS_PATH } from '@utils';
import { useShopOrigin } from '@hooks';
import { ErrorBanner } from '@components/ErrorBanner';

// == Types ================================================================

interface SetupResponse {
  hasMerchant: boolean;
}

// == Constants ============================================================

// == Functions ============================================================

// == Component ============================================================

export function FlipgiveSetupStatus(): JSX.Element {
  const shopOrigin = useShopOrigin();
  const app = useAppBridge();

  const { data, isLoading, fetchStatus, isError } = useQuery({
    queryKey: [FLIPGIVE_SETUP_STATUS_PATH],
    queryFn: async ({ signal }) => {
      if (!shopOrigin) throw new Error('Shop origin is null');

      const response = await authenticatedFetch(app)(`${FLIPGIVE_SETUP_STATUS_PATH}?shop=${shopOrigin}`, {
        method: 'GET',
        signal,
      });

      if (!response.ok) throw new Error('Failed to fetch script tags status');
      return response.json() as Promise<SetupResponse>;
    },
  });

  if (isLoading && fetchStatus === 'idle') return <SkeletonBodyText lines={1} />;
  if (isLoading) {
    return <SpinnerMessage message="Verifying the setup of your shop on FlipGive" />;
  }
  return (
    <>
      <IconMessage
        iconColor={data?.hasMerchant ? 'success' : 'critical'}
        iconSource={data?.hasMerchant ? TickSmallMinor : AlertMinor}
        message={
          data?.hasMerchant
            ? 'Your shop is setup on FlipGive'
            : 'Your shop is not setup on FlipGive, please contact us.'
        }
      />
      {isError ? <ErrorBanner message="There was a problem checking the setup of your shop on FlipGive." /> : null}
    </>
  );
}

// == Styles ===============================================================
