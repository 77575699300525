export const scriptTagsMockResponse = {
  scriptTags: [
    {
      name: 'REFERRAL_CHECK',
      displayName: 'Referral Check Script',
      src: 'https://2694850a.ngrok.io/script_tag_assets/flipgive_referral_check.min.js',
      event: 'onload',
      display_scope: 'online_store',
      id: 62459707480,
      created_at: '2019-08-12T14:49:02-04:00' as unknown as Date,
      updated_at: '2019-08-12T14:49:02-04:00' as unknown as Date,
      status: 'INSTALLED',
      success: true,
    },
  ],
  installSuccess: true,
};
