import type { InstallStatusEnum } from '@utils/api/install/constants';

export const webhooksMockResponse = {
  webhooks: [
    {
      status: 'INSTALLED' as InstallStatusEnum,
      success: true,
      id: 682222190680,
      topic: 'app/uninstalled',
      created_at: '2019-08-12T14:54:45-04:00' as unknown as Date,
      updated_at: '2019-08-12T14:54:45-04:00' as unknown as Date,
      format: 'json',
      fields: [],
      metafield_namespaces: [],
      api_version: '2023-01',
    },
    {
      status: 'INSTALLED' as InstallStatusEnum,
      success: true,
      id: 682222125144,
      topic: 'orders/create',
      created_at: '2019-08-12T14:54:45-04:00' as unknown as Date,
      updated_at: '2019-08-12T14:54:45-04:00' as unknown as Date,
      format: 'json',
      fields: [],
      metafield_namespaces: [],
      api_version: '2023-01',
    },
    {
      status: 'INSTALLED' as InstallStatusEnum,
      success: true,
      id: 682222157912,
      topic: 'refunds/create',
      created_at: '2019-08-12T14:54:45-04:00' as unknown as Date,
      updated_at: '2019-08-12T14:54:45-04:00' as unknown as Date,
      format: 'json',
      fields: [],
      metafield_namespaces: [],
      api_version: '2023-01',
    },
    {
      status: 'INSTALLED' as InstallStatusEnum,
      success: true,
      id: 682222092376,
      topic: 'shop/update',
      created_at: '2019-08-12T14:54:45-04:00' as unknown as Date,
      updated_at: '2019-08-12T14:54:45-04:00' as unknown as Date,
      format: 'json',
      fields: [],
      metafield_namespaces: [],
      api_version: '2023-01',
    },
  ],
  installSuccess: true,
};
