import { z } from 'zod';

// == Types ================================================================

// == Constants ============================================================

export const IS_API = typeof window === 'undefined';
export const IS_CLIENT = typeof window !== 'undefined';

const publicConstantsSchema = z
  .object({
    NEXT_PUBLIC_SHOPIFY_API_KEY: z.string(),
    NEXT_PUBLIC_APP_HOST_URL: z.string().url(),
    NEXT_PUBLIC_API_HOST_URL: z.string().url(),
    NEXT_PUBLIC_BUGSNAG_API_KEY: z.string(),
    // Vercel Envs
    NEXT_PUBLIC_VERCEL_ENV: z.union([z.literal('production'), z.literal('preview'), z.literal('development')]),
  })
  .strict();

export const publicConstants = publicConstantsSchema.parse({
  NEXT_PUBLIC_SHOPIFY_API_KEY: process.env.NEXT_PUBLIC_SHOPIFY_API_KEY,
  NEXT_PUBLIC_APP_HOST_URL: process.env.NEXT_PUBLIC_APP_HOST_URL,
  NEXT_PUBLIC_API_HOST_URL: process.env.NEXT_PUBLIC_API_HOST_URL,
  NEXT_PUBLIC_BUGSNAG_API_KEY: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
  // Vercel Envs
  NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
});

export const IS_DEVELOPMENT = publicConstants.NEXT_PUBLIC_VERCEL_ENV === 'development';
export const IS_PREVIEW = publicConstants.NEXT_PUBLIC_VERCEL_ENV === 'preview';
export const IS_PRODUCTION = publicConstants.NEXT_PUBLIC_VERCEL_ENV === 'production';

// == Functions ============================================================

// == Exports ==============================================================

// Paths
export const APP_HOST_URL = publicConstants.NEXT_PUBLIC_APP_HOST_URL;
export const API_HOST_URL = publicConstants.NEXT_PUBLIC_API_HOST_URL;
export const FLIPGIVE_INTENT_PATH = '/intents';
export const TOKEN_STATUS_PATH = '/api/install/token/status';
export const FLIPGIVE_SETUP_STATUS_PATH = '/api/install/flipgive_setup/status';
export const WEBHOOKS_INSTALL_PATH = '/api/install/webhooks';
export const WEBHOOKS_STATUS_PATH = '/api/install/webhooks/status';
export const SCRIPT_TAGS_INSTALL_PATH = '/api/install/script_tags';
export const SCRIPT_TAGS_STATUS_PATH = '/api/install/script_tags/status';
export const VERIFY_TOKEN_PATH = '/api/auth/verify-token';
export const FLIPGIVE_SCRIPT_VALIDATE_TOKEN_URL = '/shopify/intents/:token';

export const NEXT_PUBLIC_SHOPIFY_APP_NAME = {
  development: 'FlipGive (Development)',
  preview: 'FlipGive (Preview)',
  production: 'FlipGive',
}[publicConstants.NEXT_PUBLIC_VERCEL_ENV];

export const NEXT_PUBLIC_SHOPIFY_APP_URL_NAME = {
  development: 'flipgive-development',
  preview: 'flipgive-staging',
  production: 'flipgive',
}[publicConstants.NEXT_PUBLIC_VERCEL_ENV];

export const URLS = (
  shopOrigin: string,
): { FLIPGIVE_POST_SHOP_URL: string; FLIPGIVE_GET_SHOP_URL: string; FLIPGIVE_WEBHOOKS_URL: string } => {
  const FLIPGIVE_POST_SHOP_PATH = '/shopify/shops';
  const FLIPGIVE_GET_SHOP_PATH = '/shopify/shops/status';
  const FLIPGIVE_WEBHOOKS_PATH = '/shopify/webhooks';
  if (shopOrigin.includes('lynch-creek')) {
    const lynchCreekApiHostUrl = 'https://api.lynchcreekfundraising.com';
    return {
      FLIPGIVE_POST_SHOP_URL: `${lynchCreekApiHostUrl}${FLIPGIVE_POST_SHOP_PATH}`,
      FLIPGIVE_GET_SHOP_URL: `${lynchCreekApiHostUrl}${FLIPGIVE_GET_SHOP_PATH}`,
      FLIPGIVE_WEBHOOKS_URL: `${lynchCreekApiHostUrl}${FLIPGIVE_WEBHOOKS_PATH}`,
    };
  }
  return {
    FLIPGIVE_POST_SHOP_URL: `${API_HOST_URL}${FLIPGIVE_POST_SHOP_PATH}`,
    FLIPGIVE_GET_SHOP_URL: `${API_HOST_URL}${FLIPGIVE_GET_SHOP_PATH}`,
    FLIPGIVE_WEBHOOKS_URL: `${API_HOST_URL}${FLIPGIVE_WEBHOOKS_PATH}`,
  };
};

export const USE_DEVELOPMENT_MOCKS = false as boolean;
