import type { JsonResponse } from '@utils';
import { fetchRequest } from '@utils';

// == Types ================================================================

export interface AuthDetails {
  shopOrigin: string;
  accessToken: string;
}

// == Constants ============================================================

const SHOPIFY_ADMIN_API_VERSION = '2023-01';

// == Functions ============================================================

const shopifyAdminUrl = ({ shopOrigin }: AuthDetails, endpoint: string): string => {
  if (endpoint.includes('/admin')) return `https://${shopOrigin}${endpoint}`;
  return `https://${shopOrigin}/admin/api/${SHOPIFY_ADMIN_API_VERSION}${endpoint}`;
};

const addAccessTokenHeader = ({ accessToken }: AuthDetails, options: RequestInit): RequestInit => {
  if (!accessToken) throw new Error('Missing accessToken');

  options.headers = {
    ...options.headers,
    'X-Shopify-Access-Token': accessToken,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  return options;
};

// == Exports ==============================================================

export function shopifyAdminRequest<TResponseType>(
  authDetails: AuthDetails,
  endpoint: string,
  options: RequestInit,
): Promise<JsonResponse<Partial<TResponseType>>> {
  const url = shopifyAdminUrl(authDetails, endpoint);
  const requestOptions = addAccessTokenHeader(authDetails, options);
  return fetchRequest<TResponseType>(url, requestOptions);
}
