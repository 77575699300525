import type { ReactElement } from 'react';
import React from 'react';
import type { IconProps, IconSource } from '@shopify/polaris';
import { Icon, List, Button, Collapsible, Tooltip } from '@shopify/polaris';
import { useToggle } from '@hooks';

// == Types ================================================================

interface IconMessageProps {
  message: string;
  details: (string | undefined | ReactElement)[];
  displayDetails: boolean;
  iconSource: IconSource;
  iconColor: IconProps['color'];
  disableAction: boolean;
  actionText?: string;
  actionTooltip?: string;
  onClickAction?: () => void;
}

// == Constants ============================================================

IconMessage.defaultProps = {
  details: [],
  displayDetails: false,
  iconColor: 'black',
  disableAction: false,
};

// == Component ============================================================

export function IconMessage({
  message,
  details,
  displayDetails,
  iconSource,
  iconColor,
  actionText,
  actionTooltip,
  onClickAction,
  disableAction,
}: IconMessageProps): JSX.Element {
  const [showDetails, toggleShowDetails] = useToggle(displayDetails);

  const renderActionButton = (): JSX.Element | null => {
    if (!actionText) return null;

    const button = (
      <Button
        disabled={disableAction}
        onClick={(): void => {
          if (typeof onClickAction === 'function') onClickAction();
        }}
      >
        {actionText}
      </Button>
    );
    if (!actionTooltip) return button;
    return (
      <Tooltip activatorWrapper="p" content={actionTooltip} preferredPosition="mostSpace">
        {button}
      </Tooltip>
    );
  };

  return (
    <div className="icon-message">
      <div>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
        <Icon color={iconColor} source={iconSource} />
        <span>
          {details.length > 0 ? (
            <Button disclosure onClick={toggleShowDetails} plain size="slim">
              {message}
            </Button>
          ) : (
            message
          )}
        </span>
        {renderActionButton()}
      </div>
      {details.length > 0 && (
        <Collapsible id="basic-collapsible" open={showDetails}>
          <List type="bullet">
            {details.map((msg, index) => {
              if (!msg) return null;
              // eslint-disable-next-line react/no-array-index-key
              return <List.Item key={index}>{msg}</List.Item>;
            })}
          </List>
        </Collapsible>
      )}
    </div>
  );
}

// == Styles ===============================================================
