import { RequestError } from '@utils/api/RequestError';
import { InstallStatusEnum } from '@utils/api/install/constants';
import { URLS } from '@utils';
import type { AuthDetails } from '../shopifyAdminRequest';
import { shopifyAdminRequest } from '../shopifyAdminRequest';

// == Types ================================================================

export interface ShopifyWebhook {
  id: number;
  address: string;
  topic: string;
  created_at: Date;
  updated_at: Date;
  format: string;
  fields: string[];
  metafield_namespaces: string[];
  api_version: string;
}

export interface Webhook extends Exclude<Partial<ShopifyWebhook>, 'address'> {
  status: InstallStatusEnum;
  success: boolean;
  address?: never;
}

export interface WebhooksInstallStatus {
  installSuccess: boolean;
  webhooks: Webhook[];
}

// == Constants ============================================================

export const WEBHOOK_DATA_FORMAT = 'json';

export const SHOPIFY_WEBHOOK_TOPICS = [
  'app/uninstalled',
  'orders/create',
  'orders/updated',
  'refunds/create',
  'shop/update',
];

// == Functions ============================================================

const getWebhooksWithStatuses = (
  authDetails: AuthDetails,
  installedWebhooks?: ShopifyWebhook[],
): (
  | { topic: string; status: InstallStatusEnum; success: boolean }
  | {
      id: number;
      topic: string;
      created_at: Date;
      updated_at: Date;
      format: string;
      fields: string[];
      metafield_namespaces: string[];
      api_version: string;
      status: InstallStatusEnum;
      success: boolean;
    }
)[] => {
  const { Installed, PendingInstall, PendingUpdate, PendingDelete } = InstallStatusEnum;
  const webhooks = SHOPIFY_WEBHOOK_TOPICS.map((topic) => {
    const matchingWebhook = installedWebhooks?.find((hook) => hook.topic === topic);
    if (matchingWebhook === undefined) {
      return {
        topic,
        status: PendingInstall,
        success: false,
      };
    }

    const { FLIPGIVE_WEBHOOKS_URL } = URLS(authDetails.shopOrigin);
    // Remove address property for check below and also so that we don't send it to client as a security measure.
    const { address, ...webhookProperties } = matchingWebhook;
    const { format } = webhookProperties;
    const correctProperties = address === FLIPGIVE_WEBHOOKS_URL && format === WEBHOOK_DATA_FORMAT;
    return {
      status: correctProperties ? Installed : PendingUpdate,
      success: Boolean(correctProperties),
      ...webhookProperties,
    };
  });

  installedWebhooks?.forEach((webhook) => {
    const { topic } = webhook;
    if (SHOPIFY_WEBHOOK_TOPICS.includes(topic)) return;

    webhooks.push({
      status: PendingDelete,
      success: false,
      ...webhook,
      topic,
    });
  });
  return webhooks;
};

const getInstalledWebhooks = async (authDetails: AuthDetails): Promise<ShopifyWebhook[] | undefined> => {
  const { data, ok } = await shopifyAdminRequest<Record<'webhooks', ShopifyWebhook[]>>(authDetails, '/webhooks', {
    method: 'GET',
  });
  if (!ok || !data) {
    const clientMessage = 'Unable to fetch webhooks for your shop, please try again.';
    throw new RequestError({ clientMessage });
  }
  return data.webhooks;
};

// == Exports ==============================================================

export const webhooksInstallStatus = async (authDetails: AuthDetails): Promise<WebhooksInstallStatus> => {
  const installedWebhooks = await getInstalledWebhooks(authDetails);
  const webhooks = getWebhooksWithStatuses(authDetails, installedWebhooks);
  const installSuccess = Object.values(webhooks).every((webhook) => webhook.success);
  return { webhooks, installSuccess };
};
