import React from 'react';
import type { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppProvider as PolarisAppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { EmptyErrorState } from '@components';
import { ErrorBoundary } from '@utils';
import '@shopify/polaris/styles.css';
import '@styles/index.css';

// == Types ================================================================

// == Constants ============================================================

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 10, // 10 minutes
    },
  },
});

// == Component ============================================================

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary FallbackComponent={EmptyErrorState}>
        <PolarisAppProvider i18n={translations}>
          <Component {...pageProps} />
        </PolarisAppProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

// == Styles ===============================================================
