import { TickSmallMinor, AlertMinor } from '@shopify/polaris-icons';
import { SkeletonBodyText } from '@shopify/polaris';
import { authenticatedFetch } from '@shopify/app-bridge-utils';
import { useAppBridge } from '@shopify/app-bridge-react';
import { useQuery } from '@tanstack/react-query';
import { TOKEN_STATUS_PATH } from '@utils';
import { SpinnerMessage } from '@components/SpinnerMessage';
import { IconMessage } from '@components/IconMessage';
import { useShopOrigin } from '@hooks';
import { ErrorBanner } from '@components/ErrorBanner';

// == Types ================================================================

interface ShopifyTokenStatusProps {
  onSuccess: () => void;
}

// == Constants ============================================================

// == Component ============================================================

export function ShopifyTokenStatus({ onSuccess }: ShopifyTokenStatusProps): JSX.Element {
  const shopOrigin = useShopOrigin();
  const app = useAppBridge();

  const { isSuccess, isLoading, fetchStatus, isError } = useQuery({
    queryKey: [TOKEN_STATUS_PATH],
    queryFn: async ({ signal }) => {
      if (!shopOrigin) throw new Error('Shop origin is null');

      const response = await authenticatedFetch(app)(`${TOKEN_STATUS_PATH}?shop=${shopOrigin}`, {
        method: 'GET',
        signal,
      });
      if (!response.ok) throw new Error('Failed check Shopify token status');
      return true;
    },
    onSuccess,
  });

  if (isLoading && fetchStatus === 'idle') return <SkeletonBodyText lines={1} />;
  if (isLoading) return <SpinnerMessage message="Verifying your Shopify token" />;
  return (
    <>
      <IconMessage
        // actionText="Refresh token"
        // actionTooltip={isSuccess ? 'Your token is already valid' : ''}
        // disableAction={Boolean(isSuccess)}
        iconColor={isSuccess ? 'success' : 'critical'}
        iconSource={isSuccess ? TickSmallMinor : AlertMinor}
        message={`Your Shopify token is ${isSuccess ? 'valid' : 'invalid'}.`}
        // onClickAction={(): void => {
        //   app.dispatch(Redirect.toAdminPath({ path: `/oauth/authorize?${oauthRedirectParams()}` }));
        // }}
      />
      {isError ? <ErrorBanner message="There was a problem checking the status of your Shopify token." /> : null}
    </>
  );
}

// == Styles ===============================================================
