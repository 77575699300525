import React from 'react';
import type { BannerProps } from '@shopify/polaris';
import { Banner } from '@shopify/polaris';

// == Types ================================================================

interface ErrorBannerProps extends BannerProps {
  message: string;
}

// == Constants ============================================================

ErrorBanner.defaultProps = {
  status: 'critical',
  message: 'Something went wrong.',
};

// == Component ============================================================

export function ErrorBanner(props: ErrorBannerProps): JSX.Element {
  const { message, ...bannerProps } = props;

  return <Banner {...bannerProps}>{message}</Banner>;
}

// == Styles ===============================================================
