// == Types ================================================================

interface Params {
  message?: string;
  clientMessage?: string;
  status?: number;
}

// == Constants ============================================================

const DEFAULT_CLIENT_MESSAGE = 'There was a problem, please try again later.';

// == Functions ============================================================

// == Exports ==============================================================

export class RequestError extends Error {
  status: number;
  clientMessage: string;
  defaultClientMessage: boolean;

  constructor({ clientMessage, message, status }: Params = {}) {
    super(message || clientMessage);
    this.clientMessage = clientMessage || DEFAULT_CLIENT_MESSAGE;
    this.defaultClientMessage = !clientMessage;
    this.status = status || 500;
  }
}
